import React from "react";
import Cover from "../Cover/Cover";
import FeaturedProjects from "../FeaturedProjects/FeaturedProjects";
import Gallery from "../Gallery/Gallery";
import "./Home.css";

function Home() {
    return (
        <>
            <Cover />
            {/* <FeaturedProjects /> */}
            {/* <Gallery /> */}
        </>
    );
}

export default Home;
